import {
  Box,
  Flex,
  Text,
  Heading,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  GridItem, Grid, HStack, Button
} from "@chakra-ui/react";
import {MailIcon} from "@heroicons/react/outline";
import {useState} from "react";
import {URLS} from "../../../constants";
import {event} from "../../../services/analytics";
import Link from "next/link";

const data = {
  Title: "Email Sending for Every Need",
  Description: "Discover the power of efficient email communication for various business needs with NotionSender. From organizing meetings to managing invoices, our service streamlines your email interactions.",
  Categories: [
    {
      Category: "Business Meetings and Calls",
      EmailSubject: "Reminder: Upcoming Strategy Meeting – April 5, 2024",
      EmailContent: "Dear Team, \nJust a quick reminder about our strategy meeting scheduled for April 5, 2024, at 10:00 AM EST. We'll be discussing Q2 targets and brainstorming on new market expansion plans. \nPlease ensure to review the attached agenda and come prepared with your ideas."
    },
    {
      Category: "Invoice and Financial Transactions",
      EmailSubject: "Invoice #4567",
      EmailContent: "Dear Client, \nThank you for your business! Attached is Invoice #4567 for the services rendered in March 2024. We appreciate prompt payment by April 15, 2024. Please let us know if you have any questions."
    },
    {
      Category: "Project Management and Team Coordination",
      EmailSubject: "Project Update: Website Redesign – Week 3 Progress",
      EmailContent: "Hi Team, \nGreat work this week on the website redesign project! We've completed the initial design phase and are on track for the development stage. Please find attached the progress report and next week's tasks. Your feedback and suggestions are welcome."
    },
    {
      Category: "Event Planning and Management",
      EmailSubject: "Event Planning Update: Annual Gala 2024",
      EmailContent: "Dear Committee Members, \nI'm pleased to share that we have secured the venue for our Annual Gala 2024. The next step is finalizing the catering and entertainment options. I've attached a list of potential vendors. Please share your preferences by the end of this week."
    },
    {
      Category: "Documentation and Reports",
      EmailSubject: "Monthly Sales Report – March 2024",
      EmailContent: "Dear Team, \nPlease find attached the sales report for March 2024. The report highlights key performance metrics and areas for improvement. We will discuss this in detail in our next team meeting. Your insights will be valuable."
    }
  ]
}

export const SendingEmailsSamples = () => {

  const [loading, setLoading] = useState(false)

  return (
    <Flex
      flexDirection={{base: 'column'}}
      justifyContent="center"
      px={0}
      width="100%"
    >
      <Grid templateColumns={{base: "1fr", md: "1fr 1fr"}} gap={{base: 8, md: 20}}>
        <GridItem px={4}>
          <Heading mb={6} textAlign={"left"} fontSize={{base: "2xl", md: "3xl"}}>{data.Title}</Heading>
          <Text fontSize={{base: "md", md: "lg"}} color={"gray.600"} whiteSpace={"pre-wrap"}>
            {data.Description}
          </Text>

          <Link href={URLS.LOGIN}>
            <Button
              isLoading={loading}
              mt={5} colorScheme="blue" variant={"outline"}
              onClick={() => {
                event({action: "try_sending_emails", params: {}})
                setLoading(true)
              }}>
              <span>Try now</span>
            </Button>
          </Link>
        </GridItem>

        <GridItem>
          <Accordion allowToggle defaultIndex={[0]}>
            {data.Categories.map((category, index) => (
              <AccordionItem key={index} borderColor={"gray.200"} my={2}>
                <AccordionButton>
                  <HStack flex="1" textAlign="left" fontWeight="normal"
                    fontSize={{base: "md", md: "lg"}} py={2}>
                    <MailIcon className={"h-4 w-4 text-blue-500"}/>
                    <Text noOfLines={1}>{category.Category}</Text>
                  </HStack>
                </AccordionButton>

                <AccordionPanel py={4}>
                  <VStack align={"start"}>
                    <Box
                      my={0} shadow={"sm"} p={4} border={"1px solid"} borderColor={"gray.300"}
                      borderRadius={"md"}>
                      <Text fontWeight="bold" color={"gray.600"}>{category.EmailSubject}</Text>
                      <Text my={2} color={"gray.600"} whiteSpace={"pre-wrap"} fontSize={"md"}>{category.EmailContent}</Text>
                    </Box>
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </GridItem>
      </Grid>
    </Flex>
  );
};