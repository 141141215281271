import {PageHead} from "../core-web/components/core/templates/PageHead";
import {WebsiteLayout} from "../core-web/components/landing/WebsiteLayout";
import Navigation from "../core-web/components/landing/molecules/Navigation";
import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import Footer from "../core-web/components/landing/molecules/Footer";
import {CheckIcon} from "@chakra-ui/icons";
import {LANDING_META, LANDING_TITLE, PRO_FEATURES_LIST, URLS} from "../core-web/constants";
import Link from "next/link";
import {event} from "../core-web/services/analytics";
import Head from "next/head";
import {motion} from "framer-motion"
import MuxPlayer from "@mux/mux-player-react";
import {PropertyExtraction} from "../core-web/components/landing/molecules/PropertyExtraction";
import React, { useState} from "react";
import {SendingEmailsSamples} from "../core-web/components/landing/molecules/SendingEmailsSamples";


// Opacity animation for the header and button
const contentVariants = {
  hidden: {opacity: 0},
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};


const cardVariants = {
  offscreen: {
    y: 200,
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1,
    rotate: 0,

    transition: {
      type: "spring",
      bounce: 0.15,
      duration: 1.0,
      staggerChildren: 0.15,
      delayChildren: 0.5
    }
  }
};

const AnimatedSection = (props: any) => {
  return <motion.div
    variants={cardVariants}
    initial="offscreen"
    whileInView="onscreen"
    viewport={{once: true, amount: (props?.amount || 0.2)}}
  >
    {props.children}
  </motion.div>
}


const HeroBlock = () => {
  const [loading, setLoading] = useState(false);

  // Scale bouncing animation for the image
  const imageVariants = {
    hidden: {scale: 0.98},
    visible: {
      scale: 1,
      transition: {
        duration: 2,
        yoyo: Infinity, // For bouncing effect
      },
    },
  };

  return <Box bgColor={""}
    minH={["75vh"]}
    display={"flex"}
    alignItems={"center"}
    as={motion.div}
    initial="hidden"
    animate="visible"
    variants={contentVariants}
  >

    <Flex direction={["column", "column", "column", "row"]}
      alignItems={["center"]}
      flexGrow={1}


    >
      <Stack flex="1" alignItems={"start"} marginY={8}>

        <Container alignItems={"start"} marginX={0}>
          <Heading as={"h1"} fontSize={{base: "4xl", sm: "4xl", md: "5xl"}} fontWeight={"bold"}>
                        Easy to use <br/> Email Integration for Notion
          </Heading>
        </Container>

        <Container alignItems={"start"}>
          <Text fontSize={{base: "base", md: "lg"}} fontWeight={"normal"} color={"gray.600"}>
                        Save and send emails directly from your Notion workspace in less than a minute. No coding
                        expertise
                        required.
          </Text>
        </Container>

        <Container>
          <Link href={URLS.LOGIN} replace={true}>
            <Button
              isLoading={loading}
              marginY={4}
              colorScheme={"blue"}
              size={{base: "md", md: "lg"}}
              onClick={() => {
                event({action: "get_started_click", params: {}})
                setLoading(true)
              }}>
              <span>Get Started</span>
            </Button>
          </Link>
        </Container>
      </Stack>


      <Box flex="1"
        justifyContent={"center"}
        marginY={8}
        as={motion.div}
        initial="hidden"
        animate="visible"
        variants={imageVariants}
        whileHover={{
          // scale: 1.1,
          rotate: -3,
          transition: {duration: 0.5},
        }}
      >
        <Container>
          <Image src={"/images/landing/heroimage.png"} alt={"Notion Email Integration"}/>
        </Container>
      </Box>
    </Flex>

  </Box>
}


//@ts-ignore
const FeatureBlock = ({id, title, description, image, imageAlt, icon}) => {


  return <Box bgColor={""}
    id={id}
    display={"flex"}
    alignItems={"center"}

  >

    <Flex direction={["column", "column", "column", "row"]}
      alignItems={["center"]}
      flexGrow={1}

    >
      <Stack flex="1" alignItems={"start"} marginY={8}>

        <Container alignItems={"start"} marginX={0}>
          <HStack spacing={4} alignItems={"start"}>
            {icon && <Box marginTop={1}>{icon()}</Box>}
            <Heading as={"h2"} fontSize={{base: "2xl", md: "4xl"}} pb={{base: 4}}>
              {title}
            </Heading>
          </HStack>
        </Container>

        <Container alignItems={"start"}>
          <Text fontSize={{base: "md", md: "lg"}} color={"gray.600"}>
            {description}
          </Text>
        </Container>

      </Stack>


      <Box flex="1" marginY={8}>
        <Container>
          <Image src={image} alt={imageAlt}/>
        </Container>
      </Box>
    </Flex>

  </Box>
}


const InboxSVG = () => {
  return <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9763 13.9031C11.7606 13.6874 11.4119 13.6874 11.1961 13.9031C10.9804 14.1188 10.9804 14.4675 11.1961 14.6832L15.6094 19.0965C15.6099 19.097 15.6099 19.097 15.6105 19.0976L16 19.4871L16.3895 19.0976C16.3901 19.097 16.3901 19.097 16.3906 19.0965L20.8039 14.6832C21.0196 14.4675 21.0196 14.1188 20.8039 13.9031C20.5881 13.6874 20.2395 13.6874 20.0237 13.9031L16.5517 17.3751V2.15524C16.5517 1.85069 16.3051 1.60352 16 1.60352C15.6949 1.60352 15.4483 1.85069 15.4483 2.15524V17.3751L11.9763 13.9031Z"
      fill="#296DF0"/>
    <path
      d="M31.9895 18.5277L31.7407 18.1548H31.7357L29.7589 15.1859L28.4232 13.1843L28.4254 13.1832L26.2262 9.87891H19.8621V10.9824H25.6359L30.4243 18.1548H21.5172V22.0168H10.4828V18.1548H1.57572L6.36414 10.9824H12.1379V9.87891H5.77379L0.237793 18.1719L0.230069 18.1724L0 18.5161V18.6298V18.8483V29.5291C0 30.5586 0.838069 31.3961 1.86759 31.3961H30.1324C31.1619 31.3961 32 30.5586 32 29.5291V18.8384V18.6017L31.9923 18.5818L31.9895 18.5277Z"
      fill="#296DF0"/>
  </svg>

}

const OutboxSVG = () => {
  return <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0237 7.18674C20.2395 7.40247 20.5882 7.40247 20.8039 7.18674C21.0196 6.97102 21.0196 6.62233 20.8039 6.4066L16.3906 1.99336C16.3901 1.99281 16.3901 1.99281 16.3895 1.99226L16 1.60274L15.6105 1.99226C15.6099 1.99281 15.6099 1.99281 15.6094 1.99336L11.1961 6.4066C10.9804 6.62233 10.9804 6.97102 11.1961 7.18674C11.4119 7.40247 11.7606 7.40247 11.9763 7.18674L15.4483 3.71474L15.4483 18.9346C15.4483 19.2392 15.6949 19.4863 16 19.4863C16.3051 19.4863 16.5517 19.2392 16.5517 18.9346L16.5517 3.71474L20.0237 7.18674Z"
      fill="#296DF0"/>
    <path
      d="M31.9895 18.5297L31.7407 18.1567H31.7357L29.7589 15.1879L28.4232 13.1862L28.4254 13.1851L26.2262 9.88086H19.8621V10.9843H25.6359L30.4243 18.1567H21.5172V22.0188H10.4828V18.1567H1.57572L6.36414 10.9843H12.1379V9.88086H5.77379L0.237793 18.1738L0.230069 18.1744L0 18.5181V18.6318V18.8502V29.5311C0 30.5606 0.838069 31.3981 1.86759 31.3981H30.1324C31.1619 31.3981 32 30.5606 32 29.5311V18.8403V18.6036L31.9923 18.5838L31.9895 18.5297Z"
      fill="#296DF0"/>
  </svg>

}

const sectionItemsVariant = {
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      y: {stiffness: 1000, velocity: -100}
    }
  },
  offscreen: {
    y: 50,
    opacity: 0,
    transition: {
      y: {stiffness: 1000}
    }
  }
};

//@ts-ignore
const FeatureListBlock = ({features}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Container maxW={'6xl'} mt={10}>
        <SimpleGrid columns={{base: 1, md: 2, lg: 4}} spacing={10}>
          {features.map((feature: any) => (
            <HStack key={feature.id}
              align={'top'}
              as={motion.div}
              variants={sectionItemsVariant}

            >
              <Box color={'green.400'} px={2}>
                <Icon as={CheckIcon}/>
              </Box>
              <VStack align={'start'}>
                <Text fontWeight={600}>{feature.title}</Text>
                <Text color={'gray.600'}>{feature.text}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}


//@ts-ignore
const TestimonialsBlock = ({testimonials}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Container maxW={'6xl'} mt={4}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}>
        <Heading mb={4} textAlign={"center"} fontSize={{base: "2xl", md: "4xl"}}>Hear It From Our
                    Users</Heading>
        <Text textAlign={"center"} mb={12} color={"gray.600"}>Discover why users trust NotionSender</Text>
        <SimpleGrid columns={{base: 1, md: 2, lg: 2}} spacing={10}>
          {testimonials.map((testimonial: any) => (
            <HStack key={testimonial.author}
              align={'top'}
              as={motion.div}
              variants={sectionItemsVariant}

            >
              <VStack align={'start'}>
                <Flex alignItems={"center"}>
                  <Image width={8} height={8} src={testimonial.avatar}/>
                  <Text fontWeight={600}>{testimonial.author}</Text>
                </Flex>
                <Text color={'gray.600'}>"{testimonial.text}"</Text>

              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}

export const CTABlock = () => {
  const [loading, setLoading] = useState(false);

  return <Box
    textAlign={"center"}
    display={"flex"}
    flexDirection={"column"}
    alignItems={"center"}
    justifyContent={"center"}
  >
    <Container display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >

      <Heading>Level up your Notion workspace</Heading>
      <Text marginY={4} color={"gray.600"}>
                Enhance your Notion experience with best email integration for Notion.
      </Text>

      <Link href={URLS.LOGIN}>
        <Button colorScheme={"blue"}
          isLoading={loading}
          onClick={() => {
            event({action: "cta_click", params: {}})
            setLoading(true)
          }}>
          <span>Get Started</span>
        </Button>
      </Link>
    </Container>
  </Box>
}

//@ts-ignore
export const FAQSection = ({id, questions}) => {
  return <Box
    // minH={["30vh"]}
    id={id}
    display={"flex"}
    flexDirection={"column"}
    alignItems={"center"}
    justifyContent={"center"}
  >
    <Container display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      maxW={"3xl"}
      justifyContent={"center"}>

      <Heading marginY={8}>Frequently Asked Questions</Heading>


      {
        //@ts-ignore
        questions.map((q, idx) => {
          return <Box key={idx} marginY={6}
            as={motion.div}
            variants={sectionItemsVariant}
          >
            <Text fontSize={"lg"} fontWeight={"semibold"}>{idx + 1}. {q.question}</Text>
            <Text lineHeight={"tall"}>{q.answer}</Text>

          </Box>
        })}
    </Container>
  </Box>
}


//@ts-ignore
export const TemplatesBlock = ({id, title, description, templates, bigTemplate}) => {
  return <Stack
    id={id || "templates"}
    spacing={{base: 8, md: 16}}
    alignItems={{base: "start", md: "center"}}>

    <Stack spacing={2} as={Container} maxW={'3xl'} textAlign={'center'}>
      <Heading as={"h2"} fontSize={{base: "2xl", md: "4xl"}}>{title}</Heading>
      <Text color={'gray.600'} fontSize={'md'}>
        {description}
      </Text>
    </Stack>

    <Stack align={'start'}
      px={4}
      spacing={{base: 4, md: 8}}
      direction={{base: "column", md: "row"}}
      as={motion.div}
      variants={sectionItemsVariant}
    >
      <Image src={bigTemplate.image} alt={bigTemplate.title} width={500} shadow={"md"}
        border={"1px solid"} borderColor={"gray.100"} borderRadius={"xl"}

      />
      <VStack align={'start'} maxW={"500px"}>
        <Text fontSize="xl" fontWeight={600}>{bigTemplate.title}</Text>
        <Text color={'gray.600'}>{bigTemplate.text}</Text>
        <a href={bigTemplate.link}
          target={"_blank"}
          rel="noopener noreferrer"
          onClick={() => event({action: "template_click", params: {template: bigTemplate.title}})}>
          <Text className={"cursor-pointer"} color={'blue.400'} fontWeight={600}>Get Free Template</Text>
        </a>
      </VStack>


    </Stack>

    <Container maxW={'7xl'} mt={{base: 4, md: 16}}>
      <SimpleGrid columns={{base: 1, md: 2, lg: 3}} spacing={10}>
        {templates.map((template: any) => (
          <VStack key={template.id} align={'start'}
            as={motion.div}
            variants={sectionItemsVariant}
          >
            <Image src={template.image} alt={template.title}/>
            <Text fontWeight={600}>{template.title}</Text>
            <Text color={'gray.600'}>{template.text}</Text>
            <a href={template.link}
              target={"_blank"}
              rel="noopener noreferrer"
              onClick={() => event({action: "template_click", params: {template: template.title}})}>
              <Text className={"cursor-pointer"} color={'blue.400'} fontWeight={600}>Learn more</Text>
            </a>

          </VStack>
        ))}
      </SimpleGrid>
    </Container>
  </Stack>

}


export const PricingSection: React.FC<{ id: string }> = ({id}) => {
  const [loading, setLoading] = useState(false);
  const [isAnnualPlan, setIsAnnualPlan] = useState(false);

  const togglePlan = () => {
    // setIsAnnualPlan.toggle();
    event({
      action: isAnnualPlan ? "switch_to_monthly" : "switch_to_annual",
      params: {}
    });
  };

  return (
    <Flex id={id} direction={{base: 'column'}} justifyContent="center" width="100%">
      <Heading mb={12} textAlign={"center"} fontSize={{base: "2xl", md: "4xl"}}>Pricing</Heading>

      <Flex direction={{base: 'column-reverse', md: 'row'}} alignItems="start" justifyContent="space-around"
        width="100%">
        {/* Omitted for brevity, keep your existing Free Trial card here */}
        <Box
          width={{base: '80%', md: '400px'}}
          h={"600px"}
          mx={{base: "auto", md: 4}}
          my={{base: 4, md: 0}}
        >
          <Flex
            direction={"column"}
            justifyContent={"space-between"}
            py={{base: 6, md: 8}}
            px={{base: 6, md: 8}}
            w={'full'}
            h={"full"}
            borderWidth={1}
            borderColor={"gray.100"}
            shadow={"md"}
            borderRadius="lg"
            textAlign="center"
          >
            <Box>
              <Text fontSize="xl" mb={2} fontWeight={"normal"}>
                                Free Trial
              </Text>
              <Flex justifyContent={"center"} alignItems={"end"} mb={4}>
                <Text fontSize="2xl" fontWeight={"bold"}>$0</Text>
                <Text fontSize="xs" mb={1} mx={1} fontWeight={"normal"}>/ mo</Text>

              </Flex>

              <VStack
                fontSize={{base: "sm"}}
                alignItems={"start"} mx={"auto"} w={'fit-content'}>
                {[
                  "Enjoy most of the Premium Features",
                  "Save 100 emails monthly",
                  "Send 50 emails monthly"
                ].map((f, idx) =>
                  <Flex key={idx}
                    alignItems={"center"}
                    textAlign={"start"}
                  >

                    <CheckIcon color={idx < 1 ? "white" : "gray.700"}
                      bgColor={idx < 1 ? "green.400" : "gray.100"}
                      p={1}
                      h={4} w={4} rounded={"full"} mx={1.5}/>
                    <Text>{f}</Text>
                  </Flex>)
                }


              </VStack>
            </Box>
            <Link href={URLS.LOGIN}>
              <Button
                isLoading={loading}
                mt={5} colorScheme="blue" variant={"outline"}
                onClick={() => {
                  event({action: "free_trial_click", params: {}})
                  setLoading(true)
                }}>
                <span>Try now</span>
              </Button>
            </Link>
          </Flex>
        </Box>

        <Flex width={{base: '80%', md: '400px'}} mx={{base: "auto", md: 4}} h={"600px"} my={{base: 4, md: 0}}>
          <Flex direction={"column"} justifyContent={"space-between"} py={{base: 6, md: 8}}
            px={{base: 6, md: 8}} as={motion.div} whileHover={{scale: 1.03}} borderWidth={1} w={'full'}
            h={'full'} borderColor={"pink.300"} shadow={"md"} borderRadius="lg" textAlign="center">
            <Box>

              <Text fontSize="xl" mb={2} color={"pink.500"} fontWeight={"medium"}>💎 Premium</Text>


              <HStack spacing={0} justifyContent={"center"} mb={2}>
                <Button size="xs"
                  borderRightRadius={0}
                  colorScheme={isAnnualPlan ? "gray" : "pink"}
                  onClick={() => {
                    setIsAnnualPlan(false)
                    event({
                      action: "switch_to_monthly",
                      params: {}
                    });
                  }}
                >Monthly</Button>
                <Button size="xs"
                  borderLeftRadius={0}
                  colorScheme={!isAnnualPlan ? "gray" : "pink"}
                  onClick={() => {
                    setIsAnnualPlan(true)
                    event({
                      action: "switch_to_annual",
                      params: {}
                    });
                  }}>Annual
                  <Badge colorScheme="green" ml={1}
                    px={0.5}
                    py={0.5}
                    position={"absolute"}
                    right={-2}
                    top={-1}
                    // right={0}
                    fontSize="3xs">-20%</Badge>
                </Button>
              </HStack>

              {isAnnualPlan ? (
                <VStack justifyContent={'center'} mb={4}>
                  <Text fontSize="2xl" fontWeight={"bold"}>$199 / yr</Text>
                  <HStack justifyContent={'center'}>
                    <Text fontSize="sm" as="s" color="gray.500">$228 / yr</Text>
                    <Badge colorScheme="green" mb={1} fontSize={"2xs"}>15% Off</Badge>
                  </HStack>
                </VStack>
              ) : (
                <Text fontSize="2xl" fontWeight={"bold"} mb={2}>$19 / mo</Text>
              )}
              <VStack spacing={3} fontSize={{base: "sm"}} alignItems={"start"} mx={"auto"}
                w={'fit-content'}>
                {PRO_FEATURES_LIST.map((feature, idx) => (
                  <Flex key={idx} textAlign={"start"} alignItems={"start"}>
                    <CheckIcon color="white" bgColor={"green.400"} mt={1} p={1} h={4} w={4}
                      rounded={"full"} mx={1.5}/>
                    <Text>{feature}</Text>
                  </Flex>
                ))}
              </VStack>
            </Box>
            <Link href={URLS.LOGIN}>
              <Button mt={5} colorScheme="blue" isLoading={loading} onClick={() => {
                event({action: "landing_upgrade_click", params: {}})
                setLoading(true);
              }}>
                <span>Upgrade</span>
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

//@ts-ignore
export const GoogleFAQDataHeader = ({questions}) => {
//@ts-ignore
  const qs = questions.map((q) => {
    const {question, answer} = q
    return {
      "@type": "Question",
      "name": question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": answer
      }
    }
  })


  const parentObject = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": qs
  }
  return <Head>
    <script type="application/ld+json">
      {JSON.stringify(parentObject)}
    </script>
  </Head>
}


const VideoBlock: React.FC<{ id: string }> = ({id}) => {

  const guideLinks = [
    {
      name: "Saving emails to Notion",
      description: "This guide provides detailed instructions on saving emails to Notion using NotionSender.",
      icon: () => <InboxSVG/>,
      url: "/blog/post/saving-emails-to-notion",
    },
    {
      name: "Sending emails from Notion",
      description: "This guide provides detailed instructions on sending emails from Notion using NotionSender.",
      icon: () => <OutboxSVG/>,
      url: "/blog/post/sending-emails-from-notion",
    }
  ]

  return <Flex alignItems={"center"} justifyContent={"center"} direction={"column"} id={id}>
    <Heading as="div" my={8} textAlign={"center"} fontSize={{base: "2xl", md: "4xl"}}>Watch our step by step
            tutorials</Heading>
    <Flex direction={{base: "column", md: "row"}}>
      <Box p={4}
        mx={{base: 0, md: 4}} w="100%" maxW="750px"
        borderRadius={"none"}

      >

        <Text fontSize={"xl"} fontWeight={500} textAlign={"center"} mb={4} color={"blue.500"}>
                    How to save emails to Notion
        </Text>
        <MuxPlayer
          streamType="on-demand"
          playbackId="Qklnt02N1uMntYPnh2dy017Xxqrtkk02zWPs3b1n01eBYos"
          thumbnailTime={0}
          onPlay={() => {
            event({action: "save_email_video_play", params: {}})
          }}
        />

      </Box>

      <Box p={4}
        mx={{base: 0, md: 4}}
        w="100%" maxW="750px"
      >

        <Text fontSize={"xl"} fontWeight={500} textAlign={"center"} mb={4} color={"blue.500"}>
                    How to send emails from Notion
        </Text>

        <MuxPlayer
          streamType="on-demand"
          playbackId="8f2oHcuaep6nMd24AY7AMOLglfiKxqLv76vPdt6Cs4c"
          thumbnailTime={0}
          onPlay={() => {
            event({action: "send_email_video_play", params: {}})
          }}
        />

      </Box>
    </Flex>

    <Heading as="div" my={8} textAlign={"center"} fontSize={{base: "2xl", md: "4xl"}}>Feature guides</Heading>
    <Flex direction={{base: "column", md: "row"}} my={8}>
      {guideLinks.map((link, idx) =>
        <Box key={idx} py={6} px={6} my={{base: 4, md: 0}}
          shadow={"md"}
          border={"1px solid"} borderColor={"gray.200"}
          mx={{base: 0, md: 8}} w="100%" maxW="500px"
          borderRadius={"lg"}
        >
          <HStack justifyContent={"space-between"} alignItems={"start"}>
            <Text fontWeight={"medium"} fontSize={"2xl"}> {link.name}</Text>
            <Text>{link.icon()}</Text>
          </HStack>

          <Text color={"gray.500"} my={4}>{link.description}</Text>
          <Box my={4} color={"blue.500"} fontWeight={"medium"}>

            <Link href={link.url} target={"_blank"} onClick={
              () => {
                event({action: "guide_click", params: {guide: link.name}})
              }
            }>
                            Read Guide
            </Link>
          </Box>

        </Box>
      )}
    </Flex>

  </Flex>
}

export const HowToSection: React.FC<{ id: string }> = ({id}) => {
  const data = [
    {
      title: "Connect Your Notion Workspace",
      description: "Begin by creating your account on NotionSender. Once registered, navigate to the 'Integrations' section and select 'Connect Notion Workspace'. Follow the prompts to establish a secure connection between NotionSender and your Notion account. Grant access to at least one page.",
      image: "/images/landing/how/1.png"
    },
    {
      title: "Set Up Your Notion Database",
      description: "Either create a new Notion database or select an existing one where you'd like to save or send emails. Grant access to at least one page to create new databases with NotionSender. Ensure the NotionSender integration can access databases to use it for email integration.",
      image: "/images/landing/how/2.png"

    },
    {
      title: "Saving Emails to Notion",
      description: "Once database created or configured, NotionSender will provide you with a unique email address linked to your Notion database. Use this personal NotionSender email address to forward or send emails, and they'll automatically populate in your specified Notion database.",
      image: "/images/landing/how/3.png"

    },
    {
      title: "Sending Emails from Notion",
      description: "Inside your Notion Outbox database, create a new page and format it as you would an email, specifying the recipient, subject, and body. Once your email content is ready in Notion, use NotionSender's functionality to send it directly to your recipients.",
      image: "/images/landing/how/4.png"
    },

  ]

  return <Flex id={id} direction={"column"} justifyContent={"center"} alignItems={"center"}>
    <Container maxW={'5xl'}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}>
      <Heading as={"h2"} fontSize={{base: "2xl", md: "4xl"}} mb={16} textAlign={"center"}>How to use Notion email
                integration</Heading>
      <SimpleGrid columns={{base: 1, md: 1, lg: 1}} spacing={20}>
        {data.map((d, idx) => {


          return <Flex
            direction={{base: "column", lg: "row"}}
            key={idx}
            w={'full'}
            alignItems={"start"}
            as={motion.div}
            variants={sectionItemsVariant}
          >
            <Flex direction={"column"} alignItems={"start"} flex={"1"}>
              <Flex fontSize={"xl"} fontWeight={"medium"} alignItems={"center"} justifyContent={"center"}
                mb={2}>
                <Flex color={"white"}
                  bgColor={"blue.500"}
                  h={6} w={6}
                  fontSize={"md"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  rounded={"full"}>
                  {idx + 1}
                </Flex>
                <Text mx={2}>{d.title}</Text>
              </Flex>
              <Text fontSize={"md"} color={"gray.600"}>{d.description}</Text>
            </Flex>
            <Box ml={{base: 0, lg: 16}} my={{base: 4, lg: 0}}>
              <Image height={{base: 168.75, lg: 225}} width={{base: 300, lg: 400}} src={d.image}/>
            </Box>
          </Flex>
        })}
      </SimpleGrid>

    </Container>

  </Flex>
}

const Landing = () => {

  const faqData = [
    {
      "question": "How do I send an email to a Notion database?",
      "answer": "Start by creating an email inbox address for your Notion database using NotionSender. Once the inbox is set up, you can direct emails (using 'to', 'cc', or 'bcc') to this address, and they will be saved directly in your Notion database."
    },
    {
      "question": "How can I save emails from Gmail to Notion? (Notion Gmail integration)",
      "answer": "After creating an email address for your Notion database, you can configure your Gmail account to forward emails to Notion. Just set up a filter in your Gmail settings to forward emails to the email address you've generated."
    },
    {
      "question": "How can I save emails from Outlook to Notion? (Notion Outlook integration)",
      "answer": "Once you've created an email address for your Notion database, go to your Outlook settings and configure email forwarding. Just enable forwarding and use the generated email address as the destination. Your emails will then appear in Notion."
    },
    {
      "question": "How can I save emails from Apple Mail to Notion? (Notion Apple Mail integration)",
      "answer": "After generating an email address for your Notion database, you can set up automatic email forwarding in your Apple Mail Preferences. Add a new forwarding rule to direct emails to the Notion database email address you've created."
    },
    {
      "question": "How do I send an email from a Notion database?",
      "answer": "You can easily send emails from your Notion database with NotionSender. Once your Notion database is connected and configured, you can compose emails as Notion pages. You can specify multiple recipients, schedule delivery, and send the email by marking it as 'ready to send.’"
    },
    {
      "question": "How can I view emails in Notion?",
      "answer": "You can view emails directly in your Notion app in two ways. Either use the embeddable email widget to save a web view of your email inside Notion, or use the plain text option or image view to display your email content."
    },
    {
      "question": "How do I use template expressions when sending emails from Notion?",
      "answer": "In Notion, you can reference any database page property when drafting an email. All variables are stored in the 'page' context. For example, if you have 'name', 'cost', and 'ai_summary' as properties in your Notion database, you can reference them in your email using this syntax: {{page.name}}, {{page.cost}}, and {{page.ai_summary}}. Ensure there's no whitespace between words or at the end of the Notion property name. Properties are case-sensitive."
    },
    {
      "question": "Can I send emails to multiple recipients at once from Notion?",
      "answer": "Yes, you can. Our 'Flexible Recipients' feature allows you to use the 'email' or 'rollup' field to specify a list of recipients for your email. The 'email' field supports multiple email addresses, which should be separated by a comma."
    },
    {
      "question": "Is there a limit to the number of emails I can send or receive in Notion?",
      "answer": "With our Free Plan, you can save up to 100 emails and send up to 50 emails every 31 days. If you subscribe to our Premium Plan, you can increase the limits."
    },
    {
      "question": "Can I attach files to emails I send from Notion?",
      "answer": "Yes, you can send attachments using `file` field in Notion (For email sending databases). Max size of the attachments is currently 10MB."
    },
    {
      "question": "Can I schedule emails to be sent at a specific time from Notion?",
      "answer": "Our Premium Plan offers the scheduling of emails. You can set a specific date and time for your emails to be sent. Don’t forget to mark your email as ready to send and enable automatic email sending for the Notion database in configuration."
    },
    {
      "question": "Is there a way to send emails from Notion using my email?",
      "answer": "Yes, as a premium user, you can verify the custom email and we will use the verified email address as the sender for any emails you send from Notion."
    },
    {
      "question": "Can I customize the email design when sending from Notion?",
      "answer": "You can use Notion's rich formatting blocks like tables, callouts, color and formatting, code, and synchronized blocks. NotionSender support most of the text editing features, as well as layouts. We continue expanding supported blocks. If you have specific feature requests - feel free to email us."
    },
    {
      "question": "How do I update or change the email address for my Notion database?",
      "answer": "If you want to change the address for your Notion database - you can delete your NotionSender inbox and create a new one for this specific database in NotionSender. No data will be deleted from Notion."
    },
    {
      "question": "Can I save the email content as Notion blocks for editing?",
      "answer": "Yes, with the Plain Text save option, you can save email content as Notion blocks. NotionSender will parse your email into native Notion elements and insert them to your Notion page. This allows you to easily view and edit the email content within Notion itself."
    }
  ]

  const testimonialData = [
    {
      text: "I used to juggle between my email client and Notion constantly. Saving attachments directly in my Notion workspace has been a game-changer. With NotionSender, I no longer need to hop between multiple platforms. Everything I need is right in Notion.",
      author: "Megan W.",
      avatar: "/images/landing/avatars/1.png",
    },
    {
      text: "I was amazed at how quick the setup was. In less than a minute, my Notion workspace was connected and configured. I can direct emails to specific projects and always know where to find them. The UX is intuitive and seamless.",
      author: "Lucas M.",
      avatar: "/images/landing/avatars/6.png"
    },
    {
      text: "I often receive brief update emails from my team, and NotionSender's email parser is a lifesaver. It swiftly transforms those short emails into Notion blocks, keeping my workspace organized, searchable and up-to-date",
      author: "Oliver T.",
      avatar: "/images/landing/avatars/3.png"
    },
    {
      text: "Being able to add attachments directly in Notion and send to multiple recipients has made my email communication so much more efficient. Best Notion Gmail integration I've used",
      author: "Leonardo F.",
      avatar: "/images/landing/avatars/4.png"
    },


  ]

  return <PageHead title={LANDING_TITLE}
    description={LANDING_META}>
    <GoogleFAQDataHeader questions={faqData}/>
    <WebsiteLayout>
      <Navigation/>

      <HeroBlock/>


      <Stack spacing={[32, 32, 32, 32]}>

        <AnimatedSection>
          <FeatureBlock
            id={"receive"}
            title={"Save email to Notion"}
            description={"Transform your Notion database into an efficient email hub. Assign each database an exclusive email address and save any email, along with its essential details like date, attachments, and sender information, directly in your Notion workspace."}
            image={"/images/landing/saveimage.png"}
            imageAlt={"Save email to Notion"}
            icon={() => <InboxSVG/>}
          />


          <FeatureListBlock features={[
            {
              id: "1",
              title: "Unique Email Address for Your Notion Database",
              text: "Get a unique email address to directly store emails in your Notion database. You have the flexibility to enable or disable it whenever you need."
            },
            {
              id: "2",
              title: "Email Content saved in Notion",
              text: "Keep all your essential information in Notion. Use the Email widget to view styled emails or switch to Plain Text mode to save emails as native Notion blocks."
            },
            {
              id: "3",
              title: "Smart Data Extraction and default values",
              text: "Extract valuable information from emails and leverage AI to capture data points relevant for you. Summarize and categorize your emails easily."
            },
            {
              id: "4",
              title: "Effortless Setup in Less Than a Minute",
              text: "Get started swiftly with our user-friendly setup. Spend less than a minute, and you're ready to start receiving emails in your Notion workspace."
            }
          ]}/>
        </AnimatedSection>


        <AnimatedSection>
          <PropertyExtraction/>
        </AnimatedSection>

        <AnimatedSection>

          <FeatureBlock
            id={"send"}
            icon={() => <OutboxSVG/>}
            title={"Send email from Notion"}
            description={"Craft and send emails from Notion pages, all without leaving your Notion workspace. Automate your email-sending process with automatic delivery based on page status. Leverage the power of template expressions like {{page.Name}} to personalize your emails."}
            image={"/images/landing/sendimage.png"}
            imageAlt={"Send email from Notion"}
          />

          <FeatureListBlock features={[
            {
              id: "1",
              title: "Dynamic emails with templating",
              text: "Use Notion page properties as variables in your email content, enabling you to create dynamic, tailored emails that address your recipients' specific needs or contexts."
            },
            {
              id: "2",
              title: "Notion styled emails",
              text: "Transform your Notion pages into fully compatible emails, ready for all major email clients, with NotionSender. Unleash the full potential of Notion's formatting blocks. "
            },
            {
              id: "3",
              title: "Flexible recipients and attachments",
              text: "Add attachments to your emails directly in Notion. The email field is designed to support multiple addresses, separated by commas. Alternatively, you can connect separate recipient database."
            },
            {
              id: "4",
              title: "Scheduled and automated delivery",
              text: "Enable auto-sending for your emails. All messages marked as \"Outbox\" are sent out promptly, automating your email dispatch process."
            }
          ]}/>
        </AnimatedSection>

        <AnimatedSection>
          <SendingEmailsSamples/>
        </AnimatedSection>

        <AnimatedSection>
          <HowToSection id={"how-to"}/>
        </AnimatedSection>

        <AnimatedSection>
          <TestimonialsBlock testimonials={testimonialData}/>
        </AnimatedSection>

        <AnimatedSection>
          <PricingSection id={"pricing"}/>
        </AnimatedSection>


        <AnimatedSection>
          <VideoBlock id={"video-block"}/>
        </AnimatedSection>
        <AnimatedSection>


          <TemplatesBlock
            id={"templates"}
            title={"Notion templates to get you started"}
            description={"Use templates to quickly setup email integration to save emails to notion, send emails or newsletters."}
            bigTemplate={{
              id: "0",
              title: "Notion Email Hub Template",
              text: "Template designed to simplify email organization, featuring multiple views and pre-configured settings for efficient management. This template provides an intuitive way to keep your emails organized, saving you time and hassle.",
              image: "/images/templates/big-template.png",
              link: URLS.BIG_TEMPLATE
            }}
            templates={[
              {
                id: "1",
                title: "Email Inbox Example",
                text: "Save emails to Notion with this example. Create a unique email address for notion database.",
                image: "/images/templates/inbox.png",
                link: URLS.TEMPLATES
              },
              {
                id: "2",
                title: "Email Outbox Example",
                text: "Send emails from Notion example. Configure email status properties, create email inside the Notion and send them.",
                image: "/images/templates/outbox.png",
                link: URLS.TEMPLATES

              },
              {
                id: "3",
                title: "Newsletter Example",
                text: "Create and send newsletters from Notion example. Manage subscribers lists with Notion database. Create newsletter in the Notion.",
                image: "/images/templates/newsletter.png",
                link: URLS.TEMPLATES
              }
            ]}/>
        </AnimatedSection>

        <AnimatedSection amount={0.05}>
          <FAQSection id="faq" questions={faqData}/>
        </AnimatedSection>

        <AnimatedSection>
          <CTABlock/>
        </AnimatedSection>
        <Footer/>
      </Stack>
    </WebsiteLayout>
  </PageHead>
}


export default Landing
