import {
  Box,
  Flex,
  Text,
  Heading,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  GridItem, Grid, HStack, Button
} from "@chakra-ui/react";
import {DatabaseIcon, MailIcon} from "@heroicons/react/outline";
import {useState} from "react";
import {URLS} from "../../../constants";
import {event} from "../../../services/analytics";
import Link from "next/link";

const backgroundColors = [
  "rgba(59, 130, 246, 0.5)", "rgba(5, 150, 105, 0.5)", "rgba(239, 68, 68, 0.5)",
  "rgba(245, 158, 11, 0.5)", "rgba(124, 58, 237, 0.5)",
  "rgba(249, 115, 16, 0.5)", "rgba(219, 39, 119, 0.5)", "rgba(101, 52, 0, 0.5)", "rgba(107, 114, 128, 0.5)"
];

const textColors = [
  "rgba(59, 130, 246, 1)", "rgba(5, 150, 105, 1)", "rgba(239, 68, 68, 1)",
  "rgba(245, 158, 11, 1)", "rgba(124, 58, 237, 1)",
  "rgba(249, 115, 16, 1)", "rgba(219, 39, 119, 1)", "rgba(101, 52, 0, 1)", "rgba(107, 114, 128, 1)"
];

const data = {
  Title: "Intelligent Property Extraction",
  Description: "Extract text information from emails and save it to the Notion properties. To start extracting properties, describe data for extraction in the property description.",
  Categories: [
    {
      Category: "Financial Transactions and Billing",
      EmailSubject: "Invoice #12345 Due for Payment",
      EmailContent: "Dear Customer, Please note that your invoice #12345 issued on 01/05/2024 for the amount of $500 is due by 01/20/2024. Regards, XYZ Corp.",
      ParsingResultSample: {
        "Invoice Number": "12345",
        "Issue Date": "01/05/2024",
        "Amount Due": "$500",
        "Due Date": "01/20/2024"
      }
    },
    {
      Category: "Event and Meeting Coordination",
      EmailSubject: "Webinar Confirmation: AI in Business on 02/15/2024",
      EmailContent: "Thank you for registering for 'AI in Business' webinar. The event is scheduled for 02/15/2024 at 3 PM EST. Meeting link: [webinarlink.com].",
      ParsingResultSample: {
        "Event Title": "AI in Business",
        "Event Date": "02/15/2024",
        Time: "3 PM EST",
        "Meeting Link": "[webinarlink.com]"
      }
    },
    // {
    //   Category: "Marketing and Promotions",
    //   EmailSubject: "Exclusive Offer: 20% Off on Your Next Purchase!",
    //   EmailContent: "Hi there! We are excited to offer you a 20% discount on next purchase with us. Use the code SAVE20 at checkout. Offer valid till 01/31/2024.",
    //   ParsingResultSample: {
    //     "Offer Detail": "20% discount on next purchase",
    //     Code: "SAVE20",
    //     "Expiry Date": "01/31/2024"
    //   }
    // },
    // {
    //   Category: "Customer and Client Interaction",
    //   EmailSubject: "Feedback Request for Recent Purchase",
    //   EmailContent: "Dear John, we hope you are enjoying your new headphones. We would love to hear your thoughts. Please rate your experience out of 5. Regards, AudioTech.",
    //   ParsingResultSample: {
    //     "Customer Name": "John",
    //     Product: "headphones",
    //     Request: "rate your experience out of 5"
    //   }
    // },
    {
      Category: "Business Communications",
      EmailSubject: "Team Meeting on Project Alpha - 01/10/2024",
      EmailContent: "Reminder: The next team meeting on Project Alpha is scheduled for 01/10/2024 at 10 AM in the main conference room. Please prepare your updates.",
      ParsingResultSample: {
        "Project Name": "Alpha",
        "Meeting Date": "01/10/2024",
        Time: "10 AM",
        Location: "main conference room"
      }
    }
  ]
}


export const PropertyExtraction = () => {

  const [loading, setLoading] = useState(false)

  return (
    <Flex
      flexDirection={{base: 'column'}}
      justifyContent="center"
      px={0}
      width="100%"
    >

      <Grid templateColumns={{base: "1fr", md: "1fr 1fr"}} gap={{base: 8, md: 20}}>
        <GridItem px={4}>
          <Heading mb={6} textAlign={"left"} fontSize={{base: "2xl", md: "3xl"}}>{data.Title}</Heading>
          <Text fontSize={{base: "md", md: "lg"}} color={"gray.600"} whiteSpace={"pre-wrap"}>
            {data.Description}
          </Text>

          <Link href={URLS.LOGIN}>
            <Button
              isLoading={loading}
              mt={5} colorScheme="blue" variant={"outline"}
              onClick={() => {
                event({action: "try_property_extraction", params: {}})
                setLoading(true)
              }}>
              <span>Try now</span>
            </Button>
          </Link>
        </GridItem>


        <GridItem>
          <Accordion allowToggle defaultIndex={[0]}>
            {data.Categories.map((category, index) => (
              <AccordionItem key={index} borderColor={"gray.200"} my={2}>
                <AccordionButton>
                  <HStack flex="1" textAlign="left" fontWeight="normal"
                    fontSize={{base: "md", md: "lg"}} py={2}>
                    <MailIcon className={"h-4 w-4 text-blue-500"}/>
                    <Text noOfLines={1}>{category.Category}</Text>
                  </HStack>

                </AccordionButton>

                <AccordionPanel py={4}>

                  <VStack align={"start"}>
                    <Box
                      my={0} shadow={"sm"} p={4} border={"1px solid"} borderColor={"gray.300"}
                      borderRadius={"md"}>
                      <Text fontWeight="bold" color={"gray.600"}>{category.EmailSubject}</Text>
                      <Text my={2} color={"gray.600"} dangerouslySetInnerHTML={{
                        __html: Object.values(category.ParsingResultSample).reduce((prevText, value, idx) =>
                          prevText.split(value).join(`<span class="font-normal px-0.5 py-0.25" style="background-color:${backgroundColors[idx % backgroundColors.length]};">${value}</span>`), category.EmailContent)}}>

                      </Text>
                      {/*<Text my={2} color={"gray.600"}>{category.EmailContent}</Text>*/}
                    </Box>


                    <Box pt={0}>
                      <HStack mt={2} py={2}>
                        <DatabaseIcon className={"h-4 w-4"}/>
                        <Text fontWeight={"normal"}>Extracted Properties </Text>
                      </HStack>
                      <Box display={{ base: "block", md: "flex" }}>
                        {Object.entries(category.ParsingResultSample).map(([key, value], idx) => (
                          <Box key={key} display={{ base: "block", md: "flex" }}>
                            <Box px={3} py={1.5} key={idx}>
                              {/* Modified part */}
                              <Text fontWeight={"medium"} fontSize="sm" color={"gray.500"}>{key}</Text>
                              <Text fontWeight={"medium"} color={textColors[idx % textColors.length]}>{value}</Text>
                            </Box>
                          </Box>
                        ))}
                      </Box>

                    </Box>
                  </VStack>
                </AccordionPanel>

              </AccordionItem>
            ))}
          </Accordion>
        </GridItem>
      </Grid>

    </Flex>
  );
};